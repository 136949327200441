import { gsap } from 'gsap';
import Flickity from 'flickity';

/**
 * Class representing components for managing different UI components.
 */
export class Components {
    
    /**
     * Constructs a Components object.
     */
    constructor() {
        this.menu();
        this.carousel();
    }

    menu() {
        let body = $('body');
        
        // Function to toggle menu and body class
        function toggleMenu() {
            $('nav.menu').toggleClass('open');
            body.toggleClass('menu');
            body.toggleClass('fixed');
            body.removeClass('scrolling');

            // Update label text
            if ($('nav.menu').hasClass('open')) {
                $('.burger .label-menu').text('Close');
            } else {
                $('.burger .label-menu').text('Menu');
            }
        }
        
        // Click event for burger button in nav.menu
        $('.burger').on('click', function () {
            $(this).toggleClass('open');
            toggleMenu();
        });
    }

    /**
     * Initialize the calendar with availability data.
     */
    initializeCalendar() {
        var calendarEl = $('#calendar');
        var availabilityDataString = calendarEl.attr('data-availability');

        if (!availabilityDataString) {
            return false
        }

        try {
            var availabilityData = JSON.parse(availabilityDataString);

            var datesToHighlight = availabilityData.reduce(function(acc, item) {
                acc[item.date] = item.status;
                return acc;
            }, {});

            var picker = new Pikaday({
                field: calendarEl[0],
                bound: false,
                onDraw: function() {
                    var days = document.querySelectorAll('.pika-single .pika-day');
                    var today = new Date().setHours(0, 0, 0, 0); // Normalize today's date to midnight
                    days.forEach(function(day) {
                        var year = day.getAttribute('data-pika-year');
                        var month = (parseInt(day.getAttribute('data-pika-month')) + 1).toString().padStart(2, '0'); // Adding 1 since months are zero-based
                        var dayNumber = day.textContent.padStart(2, '0');
                        var formattedDate = `${year}-${month}-${dayNumber}`;

                        var date = new Date(`${year}-${month}-${dayNumber}`).setHours(0, 0, 0, 0); // Normalize the date to midnight

                        // Check if the date is today or later
                        if (date >= today) {
                            var status = datesToHighlight[formattedDate];

                            // Wrap the day number with a span
                            var span = document.createElement('span');
                            span.textContent = day.textContent;
                            day.innerHTML = '';
                            day.appendChild(span);

                            if (status === 'Available') {
                                day.classList.add('available-date');
                            } else if (status === 'Not Available') {
                                day.classList.add('unavailable-date');
                            }
                        }
                    });
                }
            });

            // Attach the calendar to the custom container
            document.getElementById('calendar-container').appendChild(picker.el);

        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }
    
    /**
     * Initializes carousels using Flickity within sections of the main slider.
     *
     * This function searches for elements with the class `.main .slider` and initializes
     * Flickity carousels within each of them. It sets up the next/previous button controls,
     * updates their states based on the current slide index, and handles dot navigation.
     *
     * @returns {boolean} False if no sections are found; otherwise, void.
     */
    carousel() {
        let sections = gsap.utils.toArray('.slider');

        if (!sections.length) {
            return false;
        }

        sections.forEach(section => {
            let main = $('.main-carousel', section)[0],
                next = $(section).find('.next'),
                prev = $(section).find('.prev'),
                dots = $(section).find('.dots'),
                li = dots.find('li');

            var slider = new Flickity(main, {
                cellAlign: 'left',
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                // adaptiveHeight: true,
            });

            // Event listener for slider select event
            slider.on('select', function() {
                let index = slider.selectedIndex;
                li.removeClass('active');
                li.eq(index).addClass('active');
                updateButtonOpacities(); // Update button opacities on slide select
            });

            // Event listener for dot clicks
            dots.on('click', 'li', function() {
                var index = $(this).index();
                slider.select(index);
            });

            /**
             * Event listener for the next button click.
             * Moves to the next slide.
             */
            next.on('click', function() {
                slider.next();
            });

            /**
             * Event listener for the previous button click.
             * Moves to the previous slide.
             */
            prev.on('click', function() {
                slider.previous();
            });
        });
    }
}
