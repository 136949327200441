import '../scss/build.scss';
import { Setup } from './setup';
import { Components } from './components';
import { Animations } from './animations';

/**
 * Initializes the JavaScript functionality.
 * @param {jQuery} $ - The jQuery object.
 */
(function($) {
    'use strict';

    /**
     * Instantiates setup, components, and animations.
     */
    const initialize = () => {
        const setup = new Setup();
        new Components();
        new Animations();
        
        console.log('JS Loaded.');
    };


    // Calls initialize function when the DOM is ready
    $(initialize);
})(jQuery);
