import { gsap } from 'gsap/gsap-core';
import { SplitText } from 'gsap/SplitText';
import ScrollTrigger from 'gsap/ScrollTrigger';

/**
 * Class representing animations using GSAP.
 */
export class Animations {
    /**
     * Constructs an Animations object.
     */
    constructor() {
        gsap.registerPlugin(ScrollTrigger);
        this.hero();
        this.animations();
        this.parallax();
    }

    parallax() {
        const images = $('.parallax img');

        images.each(function() {
            const image = $(this);

            gsap.to(image, {
                yPercent: 25,
                ease: 'none',
                scrollTrigger: {
                    trigger: image[0],
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true
                }
            });
        });
    }

    animations() {
        let sections = gsap.utils.toArray('section');

        if(!sections.length) {
            return false;
        }

        sections.forEach(section => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: section,
                    start: 'top 85%',
                    toggleActions: 'play',
                }
            })
            
            /**
             * Fade in.
             */
            let fade = $('.fade-in', section);
            
            if(fade.length) {
                tl.from($('.fade-in > *', section), {
                    yPercent: 10,
                    duration: 1,
                    opacity: 0,
                    stagger: 0.25,
                    ease: 'power4.out',
                }, 0.5)
            }
        });
    }

    hero() {
        let section = $('section.hero'),
            h1 = $('h1', section),
            label = $('.label', section),
            description = $('p', section),
            button = $('.buttons', section),
            scroll = $('.scroll', section),
            figure = $('figure', section),
            overlay = $('.overlay span', figure),
            image = $('img', figure);
    
        let split = new SplitText(h1, { 
            type: 'lines, words', 
            linesClass: 'parent', 
            wordsClass: 'child' 
        });
        
        let words = split.words;
    
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: 'top 85%',
                toggleActions: 'play',
            }
        });
    
        tl.from([label, description, button, scroll], {
            y: '100%',
            opacity: 0,
            ease: 'power2.out',
            stagger: 0.2,
            duration: 0.5
        }, 0).from(words, {
            y: '100%',
            ease: 'power2.out',
            stagger: 0.2,
            duration: 0.5
        }, 0);

        tl.from(overlay, {
            duration: 1,
            width: 0,
            stagger: 0.2,
            ease: 'power4.out',
        }, 0).to(overlay, {
            duration: 1,
            scaleX: 0,
            transformOrigin: 'right',
            ease: 'power4.out',
        }, 1).from(image, {
            opacity: 0,
            scale: 1.25,
            duration: 1.5,
            ease: 'power4.out',
        }, 1)
    }

    about() {
        let section = $('section.about'),
            h2 = $('h2', section),
            p = $('p', section),
            image = $('img', section),
            button = $('.button', section),
            texture = $('.texture', section),
            overlay = $('.overlay span', section);

        if(!section.length) {
            return false;
        }

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                // markers: true,
                start: 'top center',
                onEnter: function () {
                    gsap.delayedCall(1, () => {
                        $('h2', section).addClass('underline');
                    });
                },
            }
        }).from(texture, {
            duration: 1.5,
            opacity: 0,
            ease: 'power4.out',
            duration: 2,
            xPercent: 5, 
            yPercent: 5,
        }, 0).from(h2, {
            duration: 1.5,
            yPercent: 20,
            opacity: 0,
            ease: 'power4.out',
        }, 0.5).from(p, {
            yPercent: 20,
            duration: 1,
            stagger: 0.25,
            opacity: 0,
            ease: 'power4.out',
        }, 0.75).from(button, {
            yPercent: 10,
            opacity: 0,
            ease: 'power4.out',
        }, 0.75);

        tl.from(overlay, {
            duration: 1,
            width: 0,
            stagger: 0.2,
            ease: 'power4.out',
        }, 0.5).to(overlay, {
            duration: 1,
            scaleX: 0,
            transformOrigin: 'right',
            ease: 'power4.out',
        }, 1.5).to(image, {
            opacity: 1,
            scale: 1,
            duration: 1.5,
            ease: 'power4.out',
        }, 1.5)
    }
}