import { createApp } from 'vue';
import { gsap } from 'gsap';
import axios from 'axios';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ComponentA from './components/ComponentA.vue';

window.jQuery = window.$ = require('jquery');

/**
 * Class representing setup for VueJS application and Axios.
 */
export class Setup {
    /**
     * Constructs a Setup object.
     */
    constructor() {
        gsap.registerPlugin(ScrollTrigger);
        this.scroll();
    }

    scroll() {
        let body = document.getElementsByTagName('body')[0];

        window.onscroll = function() {
            if (window.pageYOffset > 100) {
                body.classList.add('scrolling')
            }else {
                body.classList.remove('scrolling')
            }
        };
    }
    
    /**
     * Sets up VueJS and Axios.
     * @function Vue
     * @memberof Setup
     */
    Vue() {
        axios.defaults.baseURL = apiUrl;

        const app = createApp({
            components: {
                'component-a': ComponentA,
            },
            mounted() {
                console.log('VueJS is ready.');
            }
        });

        app.mount('#app');
        app.config.devtools = true;
    }
}